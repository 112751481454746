import FormElement from "./FormElement";


class Phone extends FormElement {
  constructor() {
    super()
    this.name = 'Phone'
    this.type = 'Phone'
    this.title = 'Phone Number Title'
  }
}

export default Phone;