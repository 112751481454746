import React from "react";
import NavBar from "../components/NavBar";
import Hero from "../components/Landing/Hero";
import Benefits from "../components/Landing/Benefits";
import FAQ from "../components/Landing/FAQ";
import CallToAction from "../components/Landing/CallToAction";
import { benefitOne, benefitTwo } from "../components/Landing/data";

function LandingPage() {
  return (
    <div className="max-w-screen-xl mx-auto">
      {/* NavBar */}
      <NavBar />
      <Hero />

      <Benefits data={benefitOne} />
      <Benefits imgPos="right" data={benefitTwo} />

      <FAQ />
      <CallToAction />
    </div>
  );
}

export default LandingPage;
