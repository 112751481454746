import { Textarea } from "../../../components/ui/textarea";
import { Checkbox } from "../../../components/ui/checkbox";
import DateTimePicker from "../../../types/elements/DateTIme";
import { Input } from "../../../components/ui/input";

type Props = {
  element: DateTimePicker;
  onChange: (element: any) => void;
};

const DateTimePickerConfig = (props: Props) => {
  return (
    <div className="w-full py-4">
      <label className="block mb-2 font-semibold text-gray-600">Title</label>
      <Input
        placeholder="Placeholder Text"
        value={props.element.title}
        onChange={(e) =>
          props.onChange({ ...props.element, title: e.target.value })
        }
        className="w-full rounded-md p-2 border border-gray-100 shadow-sm placeholder:text-gray-500 sm:text-sm sm:leading-6"
      />

      <label className="block mt-4 mb-2 font-semibold text-gray-600">
        Description
      </label>
      <Textarea
        placeholder="Description of the Date Time Picker"
        rows={3}
        value={props.element.description}
        onChange={(e) =>
          props.onChange({ ...props.element, description: e.target.value })
        }
        className="w-full rounded-md p-2 border border-gray-100 shadow-sm placeholder:text-gray-500 sm:text-sm sm:leading-6"
      />

      <label className="block mt-4 mb-2 font-semibold text-gray-600">
        Date PickerType
      </label>

      <select
        className="w-full rounded-md p-2 border border-gray-100 shadow-sm placeholder:text-gray-500 sm:text-sm sm:leading-6"
        value={props.element.pickerType}
        onChange={(e) =>
          props.onChange({ ...props.element, datePickerType: e.target.value })
        }
      >
        <option value="date">Date</option>
        <option value="time">Time</option>
        <option value="datetime">Date and Time</option>
      </select>

      <div className="flex items-center space-x-2 my-2">
        <Checkbox
          className="toggle"
          checked={props.element.disableFutureDates}
          onChange={(e) =>
            props.onChange({
              ...props.element,
              disablePastDates: !props.element.disablePastDates,
            })
          }
        />
        <label className="font-semibold text-gray-600">
          Disable Past Dates
        </label>
      </div>

      <div className="flex items-center space-x-2 my-2">
        <Checkbox
          className="toggle"
          checked={props.element.disableFutureDates}
          onChange={(e) =>
            props.onChange({
              ...props.element,
              disableFutureDates: !props.element.disableFutureDates,
            })
          }
        />
        <label className="font-semibold text-gray-600">
          Disable Future Dates
        </label>
      </div>

      <hr className="my-4" />

      <div className="flex items-center space-x-2">
        <Checkbox />
        <label className="font-semibold text-gray-600">Required</label>
      </div>
    </div>
  );
};

export default DateTimePickerConfig;
