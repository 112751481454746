import nanoid from "../../../utils/nanoid";
import Dropdown from "../../../types/elements/Dropdown";
import { Checkbox } from "../../../components/ui/checkbox";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/textarea";

type Props = {
  element: Dropdown;
  onChange: (element: any) => void;
};

const DropdownConfig = (props: Props) => {
  const addOption = () => {
    const id = nanoid();
    props.onChange({
      ...props.element,
      options: [...props.element.options!, { id, value: "New Option" }],
    });
  };

  const removeOption = (id: string) => {
    const newOptions = props.element.options!.filter(
      (choice: { id: string; value: string }) => choice.id !== id
    );
    props.onChange({ ...props.element, options: newOptions });
  };

  return (
    <div className="w-full py-4">
      <label className="block mb-2 font-semibold text-gray-600">Title</label>
      <Input
        placeholder="Placeholder Text"
        value={props.element.title}
        onChange={(e) =>
          props.onChange({ ...props.element, title: e.target.value })
        }
      />

      <label className="block mt-4 mb-2 font-semibold text-gray-600">
        Description
      </label>
      <Textarea
        placeholder="Description of the options"
        rows={3}
        value={props.element.description}
        onChange={(e) =>
          props.onChange({ ...props.element, description: e.target.value })
        }
      />

      <label className="block mt-4 mb-2 font-semibold text-gray-600">
        Options
      </label>

      <div className="flex flex-col space-y-2">
        {props.element.options!.map(
          (option: { id: string; value: string }, index: number) => (
            <div className="flex flex-row items-center space-x-2">
              <Input
                placeholder="Enter a choice"
                key={index}
                value={option.value}
                onChange={(e) => {
                  const newOptions = [...props.element.options!];
                  newOptions[index] = {
                    id: option.id,
                    value: e.target.value,
                    checked: e.target.checked,
                  };
                  props.onChange({ ...props.element, options: newOptions });
                }}
              />
              <Button
                variant="outline"
                size="icon"
                onClick={() => removeOption(option.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </Button>
            </div>
          )
        )}

        <Button onClick={() => addOption()}>Add Option</Button>
      </div>

      <hr className="my-4" />

      <div className="flex items-center space-x-2">
        <Checkbox />
        <label className="font-semibold text-gray-600">Required</label>
      </div>
    </div>
  );
};

export default DropdownConfig;
