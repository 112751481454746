import { Input } from "../../ui/input";
import TextInput from "../../../types/elements/TextInput";
import ElementActions from "../Common/ElementActions";
import { useDispatch } from "react-redux";
import { updateResponse } from "../../../slices/formResponseSlice";

type props = {
  element: TextInput;
  selectElement: () => void;
  deleteElement: () => void;
  copyElement: () => void;
};

const TextInputElement = (props: props) => {
  const dispatch = useDispatch();

  return (
    <div
      className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100"
      onClick={props.selectElement}
    >
      <ElementActions
        onDelete={props.deleteElement}
        onCopy={props.copyElement}
      />

      <label className="block mb-2 font-medium text-gray-900">
        {props.element.title}
      </label>
      <Input
        placeholder={props.element.placeholder}
        onChange={(e) => {
          dispatch(
            updateResponse({ id: props.element.id, value: [e.target.value] })
          );
        }}
      />
      <div className="text-sm pt-2 text-gray-500">
        {props.element.description}
      </div>
    </div>
  );
};

export default TextInputElement;
