
class FormElement {
  // Common properties for all form elements
  id: string;
  name: string;
  type: string;
  title: string;
  description: string;
  placeholder: string;
  url: string;
  required: boolean;

  // For elements that have choices: Checkbox, Radio, Select, Dropdown etc.
  choices?: { id: string, value: string, checked: boolean }[];
  options?: { id: string, value: string, checked: boolean }[];
  
  // For elements that have a date picker: Date, Time, DateTime etc.
  pickerType?: string
  disablePastDates?: boolean
  disableFutureDates?: boolean

  // For elements that have a caption: Image, Video etc.
  caption?: string;

  // For elements that have a rating type: Rating
  ratingType?: string;

  value: string[];

  constructor() {
    this.id = "";
    this.name = "Default";
    this.type = "Default";
    this.title = "Input Title";
    this.placeholder = "Placeholder Text";
    this.description = "This is a description";
    this.url = "";
    this.required = false;

    this.choices = [];
    this.options = [];

    this.pickerType = "";
    this.disablePastDates = false;
    this.disableFutureDates = false;

    this.caption = "";

    this.ratingType = "";

    this.value = [];
  }
}

export default FormElement;
