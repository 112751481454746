import nanoid from "../../utils/nanoid";
import FormElement from "./FormElement";


class MultipleChoice extends FormElement {
  constructor() {
    super();
    this.name = "Multiple Choice";
    this.type = "MultipleChoice";
    this.title = "Multiple Choice Title";
    this.options = [
      {id: nanoid(), value: "Option 1", checked: false},
      {id: nanoid(), value: "Option 2", checked: false},
      {id: nanoid(), value: "Option 3", checked: false},
    ];
  }
}

export default MultipleChoice;