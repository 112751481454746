import { Textarea } from "../../../components/ui/textarea";
import { Input } from "../../../components/ui/input";
import Title from "../../../types/elements/Title";

type Props = {
  element: Title;
  onChange: (element: any) => void;
};

const TitleConfig = (props: Props) => {
  return (
    <div className="w-full py-4">
      <label className="block mb-2 font-semibold text-gray-600">Title</label>
      <Input
        placeholder="Placeholder Text"
        value={props.element.title}
        onChange={(e) =>
          props.onChange({ ...props.element, title: e.target.value })
        }
      />

      <label className="block mt-4 mb-2 font-semibold text-gray-600">
        Description
      </label>
      <Textarea
        placeholder="Description of the choices"
        rows={3}
        value={props.element.description}
        onChange={(e) =>
          props.onChange({ ...props.element, description: e.target.value })
        }
      />
    </div>
  );
};

export default TitleConfig;
