import FormElement from "./FormElement";

class VideoEmbed extends FormElement {
  constructor() {
    super()
    this.name = 'Video'
    this.type = 'Video'
    this.title = 'Video Title'
    this.description = 'This is a video'
    this.url = ''
  }
}

export default VideoEmbed