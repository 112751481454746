import CheckboxConfig from "./ElementConfig/Checkbox";
import DateTimePickerConfig from "./ElementConfig/Datepicker";
import DropdownConfig from "./ElementConfig/Dropdown";
import EmailConfig from "./ElementConfig/Email";
import FileUploadConfig from "./ElementConfig/FileUpload";
import ImageConfig from "./ElementConfig/Image";
import MultipleChoiceConfig from "./ElementConfig/MultipleChoice";
import PhoneConfig from "./ElementConfig/Phone";
import RatingConfig from "./ElementConfig/Rating";
import TextInputConfig from "./ElementConfig/TextInput";
import TitleConfig from "./ElementConfig/Title";
import VideoConfig from "./ElementConfig/Video";

type Props = {
  selectedElement: any;
  setSelectedElement: (element: any) => void;
  updateElement: (element: any) => void;
};

const configMap = new Map<string, React.FunctionComponent<any>>([
  ["Checkbox", CheckboxConfig],
  ["DateTimePicker", DateTimePickerConfig],
  ["Dropdown", DropdownConfig],
  ["Email", EmailConfig],
  ["FileUpload", FileUploadConfig],
  ["Image", ImageConfig],
  ["MultipleChoice", MultipleChoiceConfig],
  ["Phone", PhoneConfig],
  ["Rating", RatingConfig],
  ["TextInput", TextInputConfig],
  ["Title", TitleConfig],
  ["Video", VideoConfig],
]);

const FormConfig = (props: Props) => {
  const onElementChange = (element: any) => {
    props.setSelectedElement(element);
    props.updateElement(element);
  };

  if (props.selectedElement === null) {
    return (
      <div className="w-full h-full bg-white text-gray-600 p-4">
        <h2 className="text-lg font-semibold">Select an element to edit</h2>
      </div>
    );
  }

  return (
    <div className="w-full h-full bg-white text-gray-600 p-4">
      <div className="font-bold py-4">Edit {props.selectedElement?.name}</div>
      <hr />
      {configMap.get(props.selectedElement?.type)?.({
        element: props.selectedElement,
        onChange: onElementChange,
      })}
    </div>
  );
};

export default FormConfig;
