import FormElement from "./FormElement";

class TextInput extends FormElement {
  constructor() {
    super();
    this.name = "Text Input";
    this.type = "TextInput";
    this.description = "Text Input Description";
    this.placeholder = "Enter your text";
  }
}

export default TextInput;
