import { AxiosResponse } from "axios"
import FormElement from "../types/elements/FormElement"
import Form from "../types/form"
import { api } from "./config"

const BACKEND_URL = "http://localhost:3001/api/v0"

const ENDPOINTS = {
  CREATE_FORM: () => "/form",
  GET_FORM: (formID: string) => `/form/${formID}` ,
  UPDATE_FIELDS: (formID: string) => `/form/${formID}/fields`,
  UPDATE_FORM: (formID: string) => `/form/${formID}`,
}

type APIResponse = {
  data: Form
}

type FormListResponse = {
  data: Form[]
}

export const FormBuilderAPI = {

  listForms: async () => { 
    const url = BACKEND_URL + ENDPOINTS.CREATE_FORM()
    const response: AxiosResponse<any, any> = await api.request({
      url: url,
      method: "GET",
    })

    const apiResponse: FormListResponse = response.data
    return apiResponse.data
  },

  createForm: async (formName: string) => {
    const url = BACKEND_URL + ENDPOINTS.CREATE_FORM()
    const response: AxiosResponse<any, any> = await api.request({
      url: url,
      method: "POST",
      data: {
        name: formName,
      },
    })

    const apiResponse: APIResponse = response.data
    return apiResponse.data
  },

  getForm: async (formID: string) => { 
    const url = BACKEND_URL + ENDPOINTS.GET_FORM(formID)
    const response: AxiosResponse<any, any> = await api.request({
      url: url,
      method: "GET",
    })

    const apiResponse: APIResponse = response.data
    return apiResponse.data
  },

  updateFields: async (formID: string, fields: FormElement[]) => {
    const url = BACKEND_URL + ENDPOINTS.UPDATE_FIELDS(formID)
    const response: AxiosResponse<any, any> = await api.request({
      url: url,
      method: "POST",
      data: {
        formFields: fields,
      },
    })

    return response.data
  },

  updateForm: async (formID: string, form: Form) => {
    const url = BACKEND_URL + ENDPOINTS.UPDATE_FORM(formID);
    const { formFields, ...restForm } = form;
    const response: AxiosResponse<any, any> = await api.request({
      url: url,
      method: "PUT",
      data: restForm,
    });

    return response.data;
  },

}

export default FormBuilderAPI