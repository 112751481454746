import Video from "../../../types/elements/Video";
import ElementActions from "../Common/ElementActions";

type props = {
  element: Video;
  selectElement: () => void;
  deleteElement: () => void;
  copyElement: () => void;
};

const VideoElement = (props: props) => {
  return (
    <div
      className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100"
      onClick={props.selectElement}
    >
      <ElementActions
        onDelete={props.deleteElement}
        onCopy={props.copyElement}
      />

      <label className="block mb-2 font-medium text-gray-900">
        {props.element.title}
      </label>
      <video
        src={props.element.url}
        controls
        className="w-full h-32 object-cover"
      />
      <div className="text-sm pt-2 text-gray-500">
        {props.element.description}
      </div>
    </div>
  );
};

export default VideoElement;
