export type BenefitData = {
  title: string;
  desc: string;
  image: string;
  bullets: {
    title: string;
    desc: string;
  }[];
};

const benefitOne: BenefitData = {
  title: "How does it work?",
  desc: "Create forms and share it with your customers.",
  image: "/img/benefit-one.webp",
  bullets: [
    {
      title: "Create your form",
      desc: "Create a form with questions you want to ask your customers.",
    },
    {
      title: "Share the form",
      desc: "Share the form with your customers via email or social media.",
    },
    {
      title: "Get responses",
      desc: "Get responses from your customers and view them in your dashboard, whatsapp or social media account.",
    },
  ],
};

const benefitTwo: BenefitData = {
  title: "Connect with your customers quickly and dont keep them waiting",
  desc: "Avoid asking them the same set of initial questuon. Get the first set of questions answered before they even reach you.",
  image: "/img/benefit-two.webp",
  bullets: [
    {
      title: "How does it help?",
      desc: "Customer connect with you quickly with exactly what they need.",
    },
    {
      title: "Social Media Integration",
      desc: "Get enquiries only from genuine customers.",
    },
    {
      title: "Get faster responses",
      desc: "Speed up your interaction with your customers. You'll never lose a customer again.",
    },
  ],
};

export { benefitOne, benefitTwo };
