import FormElement from "./FormElement";

class Rating extends FormElement {
  constructor() {
    super()
    this.name = 'Rating'
    this.type = 'Rating'
    this.title = 'Rating Title'
    this.ratingType = 'star'
  }
}

export default Rating