import FormElement from "./FormElement";

class FileUpload extends FormElement {
  constructor() {
    super()
    this.name = 'File Upload'
    this.type = 'FileUpload'
    this.title = 'File Upload Title'
  }
}

export default FileUpload;