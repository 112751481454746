import { useDispatch } from "react-redux";
import Email from "../../../types/elements/Email";
import ElementActions from "../Common/ElementActions";
import { updateResponse } from "../../../slices/formResponseSlice";

type props = {
  element: Email;
  selectElement: () => void;
  deleteElement: () => void;
  copyElement: () => void;
};

const EmailElement = (props: props) => {
  const dispatch = useDispatch();

  return (
    <div
      className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100"
      onClick={props.selectElement}
    >
      <ElementActions
        onDelete={props.deleteElement}
        onCopy={props.copyElement}
      />

      <label className="block mb-2 font-medium text-gray-900">
        {props.element.title}
      </label>
      <input
        type="email"
        placeholder={props.element.placeholder}
        onChange={(e) => {
          dispatch(
            updateResponse({ id: props.element.id, value: [e.target.value] })
          );
        }}
        className="w-full p-2 border border-gray-100 rounded-sm placeholder:text-gray-500 sm:text-sm sm:leading-6"
      />
      <div className="text-sm pt-2 text-gray-500">
        {props.element.description}
      </div>
    </div>
  );
};

export default EmailElement;
