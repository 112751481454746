import { AxiosResponse } from "axios"
import FormResponse from "../types/response"
import { api } from "./config"

const BACKEND_URL = "http://localhost:3001/api/v0"

const ENDPOINTS = {
  SUBMIT_RESPONSE: '/response',
  LIST_RESPONSES: (formID: string) => `/form/${formID}/responses`,
}

type ResponseListResponse = {
  data: any[]
}

export const ReeponseAPI = {

  submitResponse: async (formID: string, answers: FormResponse[]) => {
    const url = BACKEND_URL + ENDPOINTS.SUBMIT_RESPONSE
    const response: AxiosResponse<any, any> = await api.request({
      url: url,
      method: "POST",
      data: {
        formID: formID,
        answers: answers
      }
    })

    const apiResponse: ResponseListResponse = response.data
    return apiResponse.data
  },

  listResponses: async (formID: string) => {
    const url = BACKEND_URL + ENDPOINTS.LIST_RESPONSES(formID)
    const response: AxiosResponse<any, any> = await api.request({
      url: url,
      method: "GET",
    })

    const apiResponse: ResponseListResponse = response.data
    return apiResponse.data
  }
}