import Image from "../../../types/elements/Image";
import ElementActions from "../Common/ElementActions";

type props = {
  element: Image;
  selectElement: () => void;
  deleteElement: () => void;
  copyElement: () => void;
};

const ImageElement = (props: props) => {
  return (
    <div
      className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100"
      onClick={props.selectElement}
    >
      <ElementActions
        onDelete={props.deleteElement}
        onCopy={props.copyElement}
      />

      <label className="block mb-2 font-medium text-gray-900">
        {props.element.title}
      </label>
      <img
        src="/img/hero.webp"
        alt="alt"
        className="w-full h-32 object-cover"
      />
    </div>
  );
};

export default ImageElement;
