import { AxiosResponse } from "axios";
import { api } from "./config";

const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:3001";

const ENDPOINTS = {
  ADD_EMAIL: () => "/api/v0/waitlist",
};

type APIResponse = {
  data: any;
};

export const WaitlistAPI = {
  addEmail: async (email: string) => {
    const url = BACKEND_URL + ENDPOINTS.ADD_EMAIL();
    const response: AxiosResponse<any, any> = await api.request({
      url: url,
      method: "POST",
      data: {
        email: email,
      },
    });

    const apiResponse: APIResponse = response.data;
    return apiResponse.data;
  },
};
