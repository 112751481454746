import nanoid from "../../utils/nanoid";
import FormElement from "./FormElement";


class CheckboxType extends FormElement {

  constructor() {
    super()
    this.name = "Checkbox"
    this.type = "Checkbox"
    this.title = "Checkbox Title"
    this.choices = [
      {id: nanoid(), value: "Choice 1", checked: false},
      {id: nanoid(), value: "Choice 2", checked: false},
      {id: nanoid(), value: "Choice 3", checked: false},
    ]
  }
}

export default CheckboxType