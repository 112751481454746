import { configureStore } from '@reduxjs/toolkit';
import formFieldsSliceReducer from '../slices/formFieldsSlice';
import formResponseSliceReducer from '../slices/formResponseSlice';
import selectedFormSliceReducer from '../slices/selectedFormSlice';

const store = configureStore({
  reducer: {
    formElement: formFieldsSliceReducer,
    formResponse: formResponseSliceReducer,
    selectedForm: selectedFormSliceReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const useAppDispatch = () => store.dispatch;
export default store;
