import FormBuilderAPI from "../apis/form-builder";
import { replaceElements, selectElements } from "../slices/formFieldsSlice";
import { updateSelectedForm } from "../slices/selectedFormSlice";
import Form from "../types/form";
import React, { MouseEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DatepickerElement from "../components/FormBuilder/Elements/Datepicker";
import DropdownElement from "../components/FormBuilder/Elements/Dropdown";
import EmailElement from "../components/FormBuilder/Elements/Email";
import FileUploadElement from "../components/FormBuilder/Elements/Fileupload";
import ImageElement from "../components/FormBuilder/Elements/Image";
import MultipleChoiceElement from "../components/FormBuilder/Elements/MultipleChoice";
import PhoneElement from "../components/FormBuilder/Elements/Phone";
import RatingElement from "../components/FormBuilder/Elements/Rating";
import TextInputElement from "../components/FormBuilder/Elements/TextInput";
import TitleElement from "../components/FormBuilder/Elements/Title";
import VideoElement from "../components/FormBuilder/Elements/Video";
import CheckboxElement from "../components/FormBuilder/Elements/Checkbox";
import FormElement from "../types/elements/FormElement";
import FormResponse from "../types/response";
import { replaceResponses, selectResponses } from "../slices/formResponseSlice";
import { Button } from "../components/ui/button";
import { ReeponseAPI } from "../apis/response";

const LiveForm = () => {
  const { formID } = useParams();
  const formElements: FormElement[] = useSelector(selectElements);
  const formResponses: FormResponse[] = useSelector(selectResponses);
  const dispatch = useDispatch();

  const elementMap = new Map<string, React.FunctionComponent<any>>([
    ["Checkbox", CheckboxElement],
    ["DateTimePicker", DatepickerElement],
    ["Dropdown", DropdownElement],
    ["Email", EmailElement],
    ["FileUpload", FileUploadElement],
    ["Image", ImageElement],
    ["MultipleChoice", MultipleChoiceElement],
    ["Phone", PhoneElement],
    ["Rating", RatingElement],
    ["TextInput", TextInputElement],
    ["Title", TitleElement],
    ["Video", VideoElement],
  ]);

  useEffect(() => {
    const createFormPage = (form: Form) => {
      let answers: FormResponse[] = [];
      form.formFields.forEach((field) => {
        const response: FormResponse = { questionID: field.id, answer: [] };
        answers.push(response);
      });

      dispatch(replaceResponses(answers));
    };

    if (formID) {
      FormBuilderAPI.getForm(formID!)
        .then((response: Form) => {
          dispatch(replaceElements(response.formFields));
          dispatch(updateSelectedForm(response));
          createFormPage(response);
        })
        .catch((error) => {
          console.error("Error fetching form details: ", error);
        });
    } else {
      console.error("Form ID not found in URL");
    }
  }, [formID, dispatch]);

  const submitForm = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    ReeponseAPI.submitResponse(formID!, formResponses);
  };

  return (
    <>
      <div className="flex max-h-screen overflow-scroll">
        <div className="flex w-full max-h-screen overflow-scroll bg-gray-100">
          <div className="grid grid-cols-8 gap-4 w-full">
            <div className="col-start-3 col-span-4">
              <div className="flex flex-col max-w-sx p-8 bg-white border border-gray-200 rounded-sm">
                {formElements.map((element, index) => {
                  const Element = elementMap.get(element.type)!;
                  return (
                    <Element
                      key={index}
                      element={element}
                      selectElement={() => {}}
                      deleteElement={() => {}}
                      copyElement={() => {}}
                    />
                  );
                })}
                <div className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100">
                  <Button type="submit" onClick={(e) => submitForm(e)}>
                    Submit
                  </Button>
                  <Button className="ml-2" variant="destructive" type="button">
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveForm;
