import Title from "../../../types/elements/Title";
import ElementActions from "../Common/ElementActions";

type props = {
  element: Title;
  selectElement: () => void;
  deleteElement: () => void;
  copyElement: () => void;
};

const TitleElement = (props: props) => {
  return (
    <div
      className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100"
      onClick={props.selectElement}
    >
      <ElementActions
        onDelete={props.deleteElement}
        onCopy={props.copyElement}
      />

      <p className="text-2xl font-bold">{props.element.title}</p>
      <p className="text-gray-500">{props.element.description}</p>
    </div>
  );
};

export default TitleElement;
