import { useEffect, useState } from "react";
import BuilderPreview from "../../components/FormBuilder/BuilderPreview";
import ElementsBar from "../../components/FormBuilder/ElementsBar";
import FormConfig from "../../components/FormBuilder/FormConfig";
import { useDispatch, useSelector } from "react-redux";
import FormElement from "../../types/elements/FormElement";
import { FormBuilderAPI } from "../../apis/form-builder";
import Form from "../../types/form";
import nanoid from "../../utils/nanoid";
import { useParams } from "react-router-dom";
import {
  replaceElements,
  removeElement,
  selectElements,
  updateElement,
} from "../../slices/formFieldsSlice";
import { updateSelectedForm } from "../../slices/selectedFormSlice";

type Props = {};

const FormBuilderPage = (props: Props) => {
  const { formID } = useParams();
  const formElements = useSelector(selectElements);
  const dispatch = useDispatch();
  const [selectedElement, setSelectedElement] = useState<any>(null);

  // Load form details from the form API

  useEffect(() => {
    if (formID) {
      FormBuilderAPI.getForm(formID!)
        .then((response: Form) => {
          dispatch(replaceElements(response.formFields));
          dispatch(updateSelectedForm(response));
        })
        .catch((error) => {
          console.error("Error fetching form details: ", error);
        });
    } else {
      console.log("form id is not defined");
    }
  }, [formID, dispatch]);

  const updateField = (element: any) => {
    let newFormElements: FormElement[] = formElements.map((el: FormElement) =>
      el.id === element.id ? element : el
    );
    FormBuilderAPI.updateFields(formID!, newFormElements)
      .then((response) => {
        dispatch(replaceElements(response.data.formFields));
      })
      .catch((error) => {
        console.error("Error updating form fields: ", error);
      });
  };

  const addField = (element: FormElement) => {
    let newFormElements: FormElement[] = [...formElements, element];
    FormBuilderAPI.updateFields(formID!, newFormElements)
      .then((response) => {
        dispatch(replaceElements(response.data.formFields));
      })
      .catch((error) => {
        console.error("Error updating form fields: ", error);
      });
  };

  const deleteField = (elementId: string) => {
    setSelectedElement(null);
    let newFormElements: FormElement[] = formElements.filter(
      (element: FormElement) => element.id !== elementId
    );
    FormBuilderAPI.updateFields(formID!, newFormElements)
      .then((response) => {
        dispatch(replaceElements(response.data.formFields));
      })
      .catch((error) => {
        console.error("Error updating form fields: ", error);
      });
  };

  const copyField = (elementId: string) => {
    const index = formElements.findIndex(
      (element: FormElement) => element.id === elementId
    );
    const element = formElements[index];
    element.id = nanoid();

    let newFormElements: FormElement[] = [...formElements];
    newFormElements.splice(index + 1, 0, element);
    FormBuilderAPI.updateFields(formID!, newFormElements)
      .then((response) => {
        dispatch(replaceElements(response.data.formFields));
      })
      .catch((error) => {
        console.error("Error updating form fields: ", error);
      });
  };

  return (
    <>
      <div className="flex max-h-screen overflow-scroll">
        <div className="flex w-full max-h-screen overflow-scroll">
          {/* Form Input Elements Bar */}
          <div className="flex-[2] 2xl:flex-[2] border-r-2 border-r-slate-200">
            <ElementsBar addField={addField} />
          </div>

          {/* Form Builder Preview */}
          <div className="flex-[7] 2xl:flex-[8] max-h-screen overflow-scroll">
            <BuilderPreview
              elements={formElements}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
              deleteElement={deleteField}
              copyElement={copyField}
            />
          </div>

          {/* Form components Config */}
          <div className="flex-[3] 2xl:flex-[2] border-l-2 border-l-slate-200">
            <FormConfig
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
              updateElement={updateField}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FormBuilderPage;
