import { useUser } from "@clerk/clerk-react";
import { PlusIcon } from "lucide-react";
import { useEffect, useState } from "react";
import FormBuilderAPI from "../../apis/form-builder";
import Form from "../../types/form";
import FormListItem from "../../components/Dashboard/FormListItem";
import { useNavigate } from "react-router-dom";

const DashboardPage = () => {
  const { isSignedIn, user, isLoaded } = useUser();
  const [forms, setForms] = useState<Form[]>([]);
  const navigate = useNavigate();

  // If the user is not signed in, redirect to the sign-in page
  if ((!isSignedIn && isLoaded) || !user) {
    window.location.href = "/sign-in";
  }

  useEffect(() => {
    FormBuilderAPI.listForms().then((response: Form[]) => {
      setForms(response);
    });
  }, [user, isSignedIn]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const newForm = () => {
    FormBuilderAPI.createForm("Untitled Form").then((response: Form) => {
      setForms([...forms, response]);
      navigate("/app/form/" + response.id + "/builder");
    });
  };

  return (
    <>
      <div className="min-h-full bg-gray-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col w-full max-h-screen flex-column">
            {/* Top Bar */}
            <div className="flex items-center justify-between m-10">
              <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  Hi, {user!.firstName}
                </h2>
              </div>

              <div className="flex mx-4 mt-0">
                <div
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={newForm}
                >
                  <PlusIcon className="h-5 w-5 mx-2" />
                  Create New Form
                </div>
              </div>
            </div>

            {/* Form List */}
            <div className="grid grid-cols-3 gap-4 mx-10">
              {forms.map((form: Form) => {
                return <FormListItem form={form as Form} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
