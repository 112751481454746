import "./globals.css";
import "@fontsource/noto-sans";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import { AppRoutes } from "./routes";

function App() {
  return <AppRoutes />;
}

export default App;
