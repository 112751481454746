import Dropdown from "../../../types/elements/Dropdown";
import ElementActions from "../Common/ElementActions";

import { cn } from "../../../utils/shadcn";
import { Button } from "../../ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "../../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { CheckIcon } from "lucide-react";
import { useState } from "react";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { useDispatch } from "react-redux";
import { updateResponse } from "../../../slices/formResponseSlice";

type props = {
  element: Dropdown;
  selectElement: () => void;
  deleteElement: () => void;
  copyElement: () => void;
};

const DropdownElement = (props: props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  const onChangeValue = (value: string) => {
    dispatch(updateResponse({ id: props.element.id, value: [value] }));
    setValue(value);
    setOpen(false);
  };

  return (
    <div
      className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100"
      onClick={props.selectElement}
    >
      <ElementActions
        onDelete={props.deleteElement}
        onCopy={props.copyElement}
      />

      <label className="block mb-2 font-medium text-gray-900">
        {props.element.title}
      </label>

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[400px] justify-between"
          >
            {value
              ? props.element.options!.find(
                  (option, index) => option.value === value
                )?.value
              : props.element.placeholder}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[400px] p-0">
          <Command>
            <CommandList>
              <CommandEmpty>No options found.</CommandEmpty>
              <CommandGroup>
                {props.element.options!.map((option, index) => (
                  <CommandItem
                    key={option.id}
                    value={option.value}
                    onSelect={(currentValue) => {
                      onChangeValue(currentValue === value ? "" : currentValue);
                    }}
                  >
                    {option.value}
                    <CheckIcon
                      className={cn(
                        "ml-auto h-4 w-4",
                        value === option.value ? "opacity-100" : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>

      {/* <select className="w-full p-2 border bg-white border-gray-100 rounded-sm">
        {props.element.options!.map((option, index) => (
          <option key={index}>{option.value}</option>
        ))}
      </select> */}
      <div className="text-sm pt-2 text-gray-500">
        {props.element.description}
      </div>
    </div>
  );
};

export default DropdownElement;
