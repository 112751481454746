import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FormBuilderAPI from "../apis/form-builder";


interface ISelectedForm {
    id: string;
    userID: string;
    isDeleted: boolean;
    name: string;
    description: string;
    shortLink: string;
    formFields: any[];
    createdAt: string;
    updatedAt: string;
}

export const updateForm = createAsyncThunk(
    'selectedForm/updateForm',
    async (data: ISelectedForm) => {
        const response = await FormBuilderAPI.updateForm(data.id, data);
        return response.data
    }
)

const initialSelectedForm = {} as ISelectedForm;

const selectedFormSlice = createSlice({
    name: 'selectedForm',
    initialState: initialSelectedForm,
    reducers: {
        updateSelectedForm: (state, action) => {
            state = action.payload;
            return state
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateForm.fulfilled, (state, action) => {
            state = action.payload;
            return state
        })
    }
});



export const { updateSelectedForm } = selectedFormSlice.actions;
export const getSelectedForm = (state: any) => state.selectedForm;

export default selectedFormSlice.reducer;
