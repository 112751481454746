import { createSlice } from "@reduxjs/toolkit";

const initialState: any[] = [];

const formElementSlice = createSlice({
  name: 'formElement',
  initialState: initialState,
  reducers: {
    replaceElements: (_, action) => {
      return action.payload;
    },
    removeElement: (state, action) => {
      return state.filter((element) => element.id !== action.payload);
    },
    updateElement: (state, action) => {
      const { id, ...elementConfig } = action.payload;
      const index = state.findIndex((element) => element.id === id);
      state[index] = { ...state[index], ...elementConfig };
    },
  }
});

export const { replaceElements, removeElement, updateElement } = formElementSlice.actions;
export const selectElements = (state: any) => state.formElement;

export default formElementSlice.reducer;