import {
  Calendar,
  CheckSquare2,
  ChevronDown,
  CopyCheck,
  FileUp,
  Image,
  MailPlus,
  Pilcrow,
  Smartphone,
  Star,
  Type,
  Video,
} from "lucide-react";
import TextInput from "../../types/elements/TextInput";
import MultipleChoice from "../../types/elements/MultipleChoice";
import Dropdown from "../../types/elements/Dropdown";
import CheckboxType from "../../types/elements/Checkbox";
import Rating from "../../types/elements/Rating";
import DateTimePicker from "../../types/elements/DateTIme";
import FileUpload from "../../types/elements/FileUpload";
import Email from "../../types/elements/Email";
import Phone from "../../types/elements/Phone";
import Title from "../../types/elements/Title";
import VideoEmbed from "../../types/elements/Video";
import ImageEmbed from "../../types/elements/Image";
import FormElement from "../../types/elements/FormElement";

type Props = {
  addField: (element: FormElement) => void;
};

const Elements = [
  {
    name: "Text Input",
    icon: <Type />,
    element: TextInput,
  },
  {
    name: "Multiple Choice",
    icon: <CopyCheck />,
    element: MultipleChoice,
  },
  {
    name: "Dropdown",
    icon: <ChevronDown />,
    element: Dropdown,
  },
  {
    name: "Checkbox",
    icon: <CheckSquare2 />,
    element: CheckboxType,
  },
  {
    name: "Rating",
    icon: <Star />,
    element: Rating,
  },
  {
    name: "Date Time Picker",
    icon: <Calendar />,
    element: DateTimePicker,
  },
  {
    name: "File Upload",
    icon: <FileUp />,
    element: FileUpload,
  },
  {
    name: "Email",
    icon: <MailPlus />,
    element: Email,
  },
  {
    name: "Phone",
    icon: <Smartphone />,
    element: Phone,
  },
  {
    name: "Title",
    icon: <Pilcrow />,
    element: Title,
  },
  {
    name: "Image",
    icon: <Image />,
    element: ImageEmbed,
  },
  {
    name: "Embed Video",
    icon: <Video />,
    element: VideoEmbed,
  },
];

const ElementsBar = (props: Props) => {
  return (
    <div className="w-full h-screen bg-white text-gray-600 p-4">
      <h2 className="text-lg font-semibold">Form Elements</h2>

      <hr className="my-4 bg-gray-200"></hr>

      <div className="flex flex-col space-y-3">
        {Elements.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-3 text-sm font-medium bg-white rounded-md hover:bg-trueGray-200"
            onClick={(e) => {
              props.addField(new item.element());
            }}
          >
            <div className="flex space-x-4 text-aling-center">
              <span className="pr-2">{item.icon}</span>
              {item.name}
            </div>
            <span className="text-md text-gray-500">+</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ElementsBar;
