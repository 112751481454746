import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { useAuth } from "@clerk/clerk-react";
import { useEffect } from "react";

function AuthedLayout() {
  const { userId, isLoaded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded && !userId) {
      navigate("/sign-in");
    }
  }, [isLoaded, userId, navigate]);

  if (!isLoaded)
    return (
      <>
        <span>Loading...</span>
      </>
    );
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}

export default AuthedLayout;
