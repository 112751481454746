import FormElement from "./FormElement";

class Email extends FormElement {
  constructor() {
    super()
    this.name = 'Email'
    this.type = 'Email'
    this.title = 'Email Title'
    this.placeholder = 'Enter your email'
  }
}

export default Email