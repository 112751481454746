import CheckboxElement from "./Elements/Checkbox";
import DatepickerElement from "./Elements/Datepicker";
import DropdownElement from "./Elements/Dropdown";
import EmailElement from "./Elements/Email";
import FileUploadElement from "./Elements/Fileupload";
import ImageElement from "./Elements/Image";
import MultipleChoiceElement from "./Elements/MultipleChoice";
import PhoneElement from "./Elements/Phone";
import RatingElement from "./Elements/Rating";
import TextInputElement from "./Elements/TextInput";
import TitleElement from "./Elements/Title";
import VideoElement from "./Elements/Video";
import FormElement from "../../types/elements/FormElement";

type Props = {
  elements: FormElement[];
  selectedElement: FormElement | null;
  setSelectedElement: (element: FormElement) => void;
  deleteElement: (elementId: string) => void;
  copyElement: (elementId: string) => void;
};

const elementMap = new Map<string, React.FunctionComponent<any>>([
  ["Checkbox", CheckboxElement],
  ["DateTimePicker", DatepickerElement],
  ["Dropdown", DropdownElement],
  ["Email", EmailElement],
  ["FileUpload", FileUploadElement],
  ["Image", ImageElement],
  ["MultipleChoice", MultipleChoiceElement],
  ["Phone", PhoneElement],
  ["Rating", RatingElement],
  ["TextInput", TextInputElement],
  ["Title", TitleElement],
  ["Video", VideoElement],
]);

const BuilderPreview = (props: Props) => {
  return (
    <div className="h-full overflow-scroll p-10 bg-gray-100">
      <div className="flex flex-col max-w-sx p-8 bg-white border border-gray-200 rounded-sm">
        {props.elements.map((element, index) => {
          const Element = elementMap.get(element.type)!;
          return (
            <Element
              key={index}
              element={element}
              selectElement={() => props.setSelectedElement(element)}
              deleteElement={() => props.deleteElement(element.id)}
              copyElement={() => props.copyElement(element.id)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BuilderPreview;
