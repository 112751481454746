import FormElement from "./FormElement"

class ImageEmbed extends FormElement {
  constructor() {
    super()
    this.name = 'Image'
    this.type = 'Image'
    this.title = 'Image Title'
    this.url = ''
    this.caption = ''
  }
}

export default ImageEmbed