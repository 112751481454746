import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/textarea";
import CheckboxType from "../../../types/elements/Checkbox";
import nanoid from "../../../utils/nanoid";

type props = {
  element: CheckboxType;
  onChange: (element: any) => void;
};

const CheckboxConfig = (props: props) => {
  const addChoice = () => {
    const id = nanoid();
    props.onChange({
      ...props.element,
      choices: [...props.element.choices!, { id, value: "New Option" }],
    });
  };

  const removeChoice = (id: string) => {
    const newChoices = props.element.choices!.filter(
      (choice: { id: string; value: string }) => choice.id !== id
    );
    props.onChange({ ...props.element, choices: newChoices });
  };

  return (
    <div className="w-full py-4">
      <label className="block mb-2 font-semibold text-gray-600">Title</label>
      <Input
        placeholder="Placeholder Text"
        value={props.element.title}
        onChange={(e) =>
          props.onChange({ ...props.element, title: e.target.value })
        }
        className="w-full rounded-md p-2 border border-gray-100 shadow-sm placeholder:text-gray-500 sm:text-sm sm:leading-6"
      />
      <label className="block mt-4 mb-2 font-semibold text-gray-600">
        Description
      </label>
      <Textarea
        placeholder="Description of the choices"
        rows={3}
        value={props.element.description}
        onChange={(e) =>
          props.onChange({ ...props.element, description: e.target.value })
        }
      />

      <hr className="my-4" />

      <label className="block mt-4 mb-2 font-semibold text-gray-600">
        Choices
      </label>

      <div className="flex flex-col space-y-2">
        {props.element.choices!.map(
          (option: { id: string; value: string }, index: number) => (
            <div className="flex flex-row items-center space-x-2">
              <Input
                placeholder="Enter a choice"
                key={index}
                value={option.value}
                onChange={(e) => {
                  const newChoices = [...props.element.choices!];
                  newChoices[index] = {
                    id: option.id,
                    value: e.target.value,
                    checked: e.target.checked,
                  };
                  props.onChange({ ...props.element, choices: newChoices });
                }}
              />
              <Button
                variant="outline"
                size="icon"
                onClick={() => removeChoice(option.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </Button>
            </div>
          )
        )}

        <Button onClick={() => addChoice()}>Add Option</Button>
      </div>

      <hr className="my-4" />

      <div className="flex items-center space-x-2">
        <Checkbox />
        <label className="font-semibold text-gray-600">Required</label>
      </div>
    </div>
  );
};

export default CheckboxConfig;
