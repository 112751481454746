import { InboxIcon, LinkIcon, PencilIcon, Share2Icon } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import Form from "../../types/form";

type Props = {
  form: Form;
};

const FormListItem = ({ form }: Props) => {
  return (
    <div className="bg-gray-50 border border-gray-200 rounded-md p-4">
      <div className="flex flex-col w-full items-left mb-8">
        <div className="text-xl font-semibold">
          {" "}
          <Link
            to={`form/${form.id}/builder`}
            className="text-xl font-semibold"
          >
            {form.name}
          </Link>
        </div>
        <div className="flex w-full py-2 text-gray-500">
          <Link
            to={`/${form.shortLink}`}
            className="flex items-center text-md font-normal"
          >
            kwikforms.com/{form.shortLink}
            <LinkIcon className="w-4 h-4 mx-2" />
          </Link>
        </div>
      </div>

      <div className="flex">
        <div className="flex text-md mr-4 items-center justify-between">
          <Link
            className="flex items-center justify-between"
            to={`form/${form.id}/builder`}
          >
            <PencilIcon className="w-4 h-4 mx-2" />
            Edit
          </Link>
        </div>
        <div className="flex text-md mr-4 items-center justify-between">
          <Link
            className="flex items-center justify-between"
            to={`form/${form.id}/share`}
          >
            <Share2Icon className="w-4 h-4 mx-2" />
            Share
          </Link>
        </div>
        <div className="flex text-md mr-4 items-center justify-between">
          <Link
            className="flex items-center justify-between"
            to={`form/${form.id}/responses`}
          >
            <InboxIcon className="w-4 h-4 mx-2" />
            Responses
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FormListItem;
