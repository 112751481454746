import { RadioGroup, RadioGroupItem } from "../../ui/radio-group";
import MultipleChoice from "../../../types/elements/MultipleChoice";
import ElementActions from "../Common/ElementActions";
import { Label } from "../../ui/label";
import { useAppDispatch } from "../../../store/store";
import { useState } from "react";
import { updateResponse } from "../../../slices/formResponseSlice";

type props = {
  element: MultipleChoice;
  selectElement: () => void;
  deleteElement: () => void;
  copyElement: () => void;
};

const MultipleChoiceElement = (props: props) => {
  const dispatch = useAppDispatch();

  const [elementState, setElementState] = useState<MultipleChoice>(
    props.element
  );

  const onChangeChoice = (value: string) => {
    // Create a new array of choices
    const newChoices = elementState.options!.map((choice) => {
      if (choice.value === value) {
        return { ...choice, checked: !choice.checked };
      }
      return choice;
    });

    // Update the element state
    setElementState({ ...elementState, options: newChoices });

    // Create a list of selected choice values
    const selectedChoices = newChoices
      .filter((choice) => choice.checked)
      .map((choice) => choice.value);
    dispatch(updateResponse({ id: props.element.id, value: selectedChoices }));
  };

  return (
    <div
      className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100"
      onClick={props.selectElement}
    >
      <ElementActions
        onDelete={props.deleteElement}
        onCopy={props.copyElement}
      />

      <label className="block mb-2 font-medium text-gray-900">
        {props.element.title}
      </label>
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <RadioGroup
            onValueChange={(value) => {
              onChangeChoice(value);
            }}
          >
            {props.element.options!.map((option, index) => (
              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  value={option.value}
                  id={option.id}
                  key={option.id}
                />
                <Label htmlFor={option.id}>{option.value}</Label>
              </div>
            ))}
          </RadioGroup>
        </div>
      </div>
      <div className="text-sm pt-2 text-gray-500">
        {props.element.description}
      </div>
    </div>
  );
};

export default MultipleChoiceElement;
