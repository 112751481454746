import { CopyIcon, Trash2Icon } from "lucide-react";
import React from "react";

type Props = {
  onDelete: () => void;
  onCopy: () => void;
};

const ElementActions = (props: Props) => {
  return (
    <div className="flex justify-end invisible group-hover:visible">
      <CopyIcon
        className="w-4 h-4 mx-2 text-gray-300 hover:text-gray-500"
        onClick={props.onCopy}
      />
      <Trash2Icon
        className="w-4 h-4 mx-2 text-red-300 hover:text-red-500"
        onClick={(e) => {
          e.stopPropagation();
          props.onDelete();
        }}
      />
    </div>
  );
};

export default ElementActions;
