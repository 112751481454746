import React from "react";
import { BenefitData } from "./data";

type BenefitsProps = {
  data: BenefitData;
  imgPos?: string;
};

const Benefits = (props: BenefitsProps) => {
  const { data, imgPos } = props;
  return (
    <>
      <div className="container p-8 mx-auto xl:px-0 ">
        <div className="flex flex-wrap mb-20 lg:gap-10 lg:flex-nowrap ">
          <div
            className={`flex items-center justify-center w-full lg:w-1/2 ${
              props.imgPos === "right" ? "lg:order-1" : ""
            }`}
          >
            <div>
              <img
                src={data.image}
                width="521"
                height="auto"
                alt="Benefits"
                className={"object-cover"}
              />
            </div>
          </div>

          <div
            className={`flex flex-wrap items-center w-full lg:w-1/2 ${
              imgPos === "right" ? "lg:justify-end" : ""
            }`}
          >
            <div>
              <div className="flex flex-col w-full mt-4">
                <h3 className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-gray-800 lg:leading-tight lg:text-4xl">
                  {data.title}
                </h3>

                <p className="max-w-2xl py-4 text-lg leading-normal text-gray-500 lg:text-xl xl:text-xl">
                  {data.desc}
                </p>
              </div>

              <div className="w-full mt-5">
                {data.bullets.map((item, index) => (
                  <Benefit key={index} title={item.title} index={index + 1}>
                    {item.desc}
                  </Benefit>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type BenefitProps = {
  title: string;
  index: number;
  children: any;
};

const Benefit = (props: BenefitProps) => {
  return (
    <>
      <div className="flex items-start mt-8 space-x-3">
        <div className="flex items-center justify-center flex-shrink-0 mt-1 bg-indigo-500 rounded-md w-11 h-11 ">
          <span className="text-xl text-bold text-gray-100">{props.index}</span>
        </div>
        <div>
          <h4 className="text-xl font-medium text-gray-800">{props.title}</h4>
          <p className="mt-1 text-gray-500">{props.children}</p>
        </div>
      </div>
    </>
  );
};

export default Benefits;
