import { Popover, PopoverContent } from "../../ui/popover";
import DateTimePicker from "../../../types/elements/DateTIme";
import ElementActions from "../Common/ElementActions";
import { PopoverTrigger } from "@radix-ui/react-popover";
import { Button } from "../../ui/button";
import { useState } from "react";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "../../ui/calendar";
import { format } from "date-fns";
import { cn } from "../../../utils/shadcn";
import { useAppDispatch } from "../../../store/store";
import { updateResponse } from "../../../slices/formResponseSlice";

type props = {
  element: DateTimePicker;
  selectElement: () => void;
  deleteElement: () => void;
  copyElement: () => void;
};

const DatepickerElement = (props: props) => {
  const [date, setDate] = useState<Date>();
  const dispatch = useAppDispatch();

  const onChangeDate = (date: Date) => {
    dispatch(updateResponse({ id: props.element.id, value: [date] }));
    setDate(date);
  };

  return (
    <div
      className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100"
      onClick={props.selectElement}
    >
      <ElementActions
        onDelete={props.deleteElement}
        onCopy={props.copyElement}
      />

      <label className="block mb-2 font-medium text-gray-900">
        {props.element.title}
      </label>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "w-[240px] justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, "PPP") : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={date}
            onSelect={(date) => {
              onChangeDate(date!);
            }}
            initialFocus
          />
        </PopoverContent>
      </Popover>
      <div className="text-sm pt-2 text-gray-500">
        {props.element.description}
      </div>
    </div>
  );
};

export default DatepickerElement;
