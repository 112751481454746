import FormElement from "./FormElement";

class Title extends FormElement {
  constructor() {
    super()
    this.name = 'Title'
    this.type = 'Title'
  }
}

export default Title;