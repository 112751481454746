import { PhotoIcon } from "@heroicons/react/24/solid";
import FileUpload from "../../../types/elements/FileUpload";
import ElementActions from "../Common/ElementActions";
import { useDispatch } from "react-redux";

type props = {
  element: FileUpload;
  selectElement: () => void;
  deleteElement: () => void;
  copyElement: () => void;
};

const FileUploadElement = (props: props) => {
  return (
    <div
      className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100"
      onClick={props.selectElement}
    >
      <ElementActions
        onDelete={props.deleteElement}
        onCopy={props.copyElement}
      />

      <label className="block mb-2 font-medium text-gray-900">
        {props.element.title}
      </label>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <div className="text-center">
          <PhotoIcon
            className="mx-auto h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 hover:text-indigo-500"
            >
              <span>Upload a file</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs leading-5 text-gray-600">File up to 10MB</p>
        </div>
      </div>
      <div className="text-sm pt-2 text-gray-500">
        {props.element.description}
      </div>
    </div>
  );
};

export default FileUploadElement;
