import { Dialog, Transition } from "@headlessui/react";
import NavBar from "../components/NavBar";
import React, { Fragment, useState } from "react";
import { WaitlistAPI } from "../apis/waitlist";

type Plan = {
  name: string;
  price: {
    monthly: string;
    annual: string;
    discount: string;
    original: string;
  };
  popular: boolean;
  features: string[];
  button: {
    text: string;
    link: string;
  };
};

const pricing: Plan[] = [
  {
    name: "Personal",
    price: {
      monthly: "$0",
      annual: "$0",
      discount: "0%",
      original: "$0",
    },
    popular: false,
    features: [
      "Lifetime free",
      "Unlimited responses on Whatsapp",
      "Max 2 Forms",
      "No Responses on Dashboard",
      "No Custom Domains",
      "No White Labeling",
    ],
    button: {
      text: "Get Started",
      link: "/",
    },
  },
  {
    name: "Startup",
    price: {
      monthly: "$19",
      annual: "$16",
      discount: "10%",
      original: "$24",
    },
    popular: true,
    features: [
      "All Free Features",
      "Max 25 Forms",
      "Unlimited Responses on Dashboard",
      "Custom Domains",
      "White Labeling",
      "Team Collaboration",
    ],
    button: {
      text: "Get Started",
      link: "#",
    },
  },
  {
    name: "Enterprise",
    price: {
      monthly: "$69",
      annual: "$69",
      discount: "20%",
      original: "$99",
    },
    popular: false,
    features: [
      "All Startup Features",
      "Max 100 Forms",
      "24/7 Priority Support",
      "API Access",
      "Custom Integrations",
      "Team Routing",
    ],
    button: {
      text: "Contact us",
      link: "/contact",
    },
  },
];

const PricingCard = (plan: Plan) => {
  return (
    <div>
      <div className="flex flex-col w-full order-first lg:order-none border-2 border-[#D8DEE9] border-opacity-50 py-5 px-6 rounded-md">
        <div className="text-center">
          <h4 className="text-2xl font-medium text-gray-800">{plan.name}</h4>
          <p className="mt-3 text-4xl font-bold text-black md:text-4xl">
            {plan.price && typeof plan.price === "object"
              ? plan.price.monthly
              : plan.price}
          </p>
          {plan.price.original && (
            <p className="mt-1 text-xl font-medium text-gray-400 line-through md:text-2xl">
              {plan.price.original}
            </p>
          )}
        </div>
        <ul className="grid mt-8 text-left gap-y-4">
          {plan.features.map((item) => (
            <li className="flex items-start text-gray-800">
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Waitlist = () => {
  const [openModel, setOpenModel] = useState(false);
  const [email, setEmail] = React.useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModel(true);
    // Call the API to save the email to the waitlist
    WaitlistAPI.addEmail(email)
      .then(() => {
        // Open a modal to show the user that they have been added to the waitlist
        setEmail("");
      })
      .catch(() => {
        alert("Failed to add email to waitlist");
      });
  };

  return (
    <div className="max-w-screen-xl mx-auto">
      <NavBar />

      <div className="flex flex-col items-center justify-center mt-20 px-4">
        <h2 className="text-4xl font-bold text-black md:text-5xl">
          Join our waitlist
        </h2>
        <p className="mt-3 text-lg text-gray-400">
          Be the first to know when we launch
        </p>

        <form
          className="flex flex-col w-full gap-3 mt-4 max-w-md"
          onSubmit={handleSubmit}
        >
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="border-2 border-[#D8DEE9] border-opacity-50 px-3 py-2 rounded-md"
          />
          <button className="bg-black text-white py-2 rounded-md">
            Join Waitlist
          </button>
        </form>
      </div>

      <div className="text-center mt-20 px-4">
        <h2 className="text-4xl font-bold text-black md:text-5xl">
          Here are our pricing plans
        </h2>
        <p className="mt-3 text-lg text-gray-400">
          Start with our free plan and switch to premium as you grow
        </p>
      </div>
      <div className="grid md:grid-cols-3 gap-10 mx-auto max-w-screen-lg mt-12 px-4">
        {pricing.map((item) => (
          <PricingCard {...item} />
        ))}
      </div>

      <Transition appear show={openModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setOpenModel(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Thank You!
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      You have been added to the waitlist. We will notify you
                      when we launch, with a discount code exclusive to you.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setOpenModel(false)}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Waitlist;
