// Pages and Components
import DashboardPage from "./pages/Dashboard/Dashboard";
import FormBuilderPage from "./pages/Form/FormBuilder";
import RootLayout from "./pages/RootLayout";
import LandingPage from "./pages/LandingPage";
import FormLayout from "./pages/Form/FormLayout";
import Share from "./pages/Form/Share";
import ResponsesPage from "./pages/Form/Responses";
import AuthedLayout from "./pages/AuthedLayout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SignInPage from "./pages/SignIn";
import LiveForm from "./pages/LiveForm";
import Waitlist from "./pages/Waitlist";

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/waitlist",
        element: <Waitlist />,
      },
      {
        path: "/sign-in",
        element: <SignInPage />,
      },
      {
        path: "/app",
        element: <AuthedLayout />,
        children: [
          {
            path: "/app",
            element: <DashboardPage />,
          },
          {
            path: "/app/form/:formID",
            element: <FormLayout />,
            children: [
              {
                path: "/app/form/:formID/builder",
                element: <FormBuilderPage />,
              },
              {
                path: "/app/form/:formID/share",
                element: <Share />,
              },
              {
                path: "/app/form/:formID/responses",
                element: <ResponsesPage />,
              },
            ],
          },
        ],
      },
      {
        path: "/:formID",
        element: <LiveForm />,
      },
    ],
  },
]);

export const AppRoutes = () => {
  return <RouterProvider router={router} />;
};
