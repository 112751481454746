import { Link, useLocation } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { getSelectedForm, updateForm } from "../slices/selectedFormSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../store/store";

const NavBar = () => {
  const { userId, isLoaded } = useAuth();
  const location = useLocation();
  // get the current open form name from selector
  const currentForm = useSelector(getSelectedForm);
  const dispatch = useAppDispatch();

  const [editingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState("");

  const setCurrentForm = (data: any) => {
    dispatch(updateForm(data));
  };

  useEffect(() => {
    setTitle(currentForm.name);
  }, [currentForm]);

  return (
    <div className="w-full bg-white">
      <nav
        className="mx-auto flex items-center justify-between p-4"
        aria-label="Global"
      >
        {location.pathname.includes("app/form/") ? (
          <>
            <Link
              to={`${userId && isLoaded ? "/app" : "/"}`}
              className="flex items-center justify-begin lg:flex-1"
            >
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </Link>
            {editingTitle ? (
              <input
                type="text"
                className="ml-4 font-semibold text-xl"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={(e) => {
                  setEditingTitle(false);
                  setCurrentForm({ ...currentForm, name: e.target.value });
                }}
              />
            ) : (
              <div
                className="ml-4 font-semibold text-xl"
                onClick={() => setEditingTitle(true)}
              >
                {currentForm.name}
              </div>
            )}
          </>
        ) : (
          <Link
            to={`${userId && isLoaded ? "/app" : "/"}`}
            className="flex items-center justify-begin lg:flex-1"
          >
            <div className="ml-4 font-semibold text-xl">kwikforms</div>
          </Link>
        )}

        <div className="flex flex-1 justify-end items-center	">
          {/* <div className="mx-4 text-md leading-6 text-gray-900">
            <Link to="/help">Help</Link>
          </div> */}
          <button
            type="button"
            className="px-6 py-2 text-white bg-indigo-600 rounded-md md:ml-5"
          >
            <Link to="/waitlist">Try for free</Link>
          </button>
          {/* <SignedOut>
            <SignInButton forceRedirectUrl="/app">
              <button
                type="button"
                className="px-6 py-2 text-white bg-indigo-600 rounded-md md:ml-5"
              >
                Sign In
              </button>
            </SignInButton>
          </SignedOut>
          <SignedIn>
            <UserButton
              appearance={{
                elements: {
                  userButtonTrigger: {
                    "&:hover, &:focus, &:active": {
                      boxShadow: "none",
                    },
                  },
                },
              }}
              afterSignOutUrl="/sign-in"
            />
          </SignedIn> */}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
