import nanoid from "../../utils/nanoid";
import FormElement from "./FormElement";


class Dropdown extends FormElement {

  constructor() {
    super();
    this.name = "Dropdown";
    this.type = "Dropdown";
    this.title = "Dropdown Title";
    this.options = [
      {id: nanoid(), value: "Option 1", checked: false},
      {id: nanoid(), value: "Option 2", checked: false},
      {id: nanoid(), value: "Option 3", checked: false},
    ];
    this.placeholder = "Select an option";
  }
}

export default Dropdown;