import React, { useEffect } from "react";
import BuilderHeader from "../../components/FormBuilder/BuilderHeader";
import { Outlet, useNavigate } from "react-router-dom";

function FormLayout() {
  const publishForm = () => {};
  const navigate = useNavigate();
  useEffect(() => {
    navigate("builder");
  }, [navigate]);
  return (
    <>
      <BuilderHeader publishForm={publishForm} />
      <Outlet />
    </>
  );
}

export default FormLayout;
