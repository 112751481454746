import FormElement from "./FormElement";

class DateTimePicker extends FormElement {

  constructor() {
    super()
    this.name = 'Date & Time Picker';
    this.type = 'DateTimePicker';
    this.title = 'Date & Time Picker Title';
    this.pickerType = 'date';
    this.disablePastDates = false;
    this.disableFutureDates = false;
  }
}

export default DateTimePicker