import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { EyeIcon, RefreshCwIcon } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { cn } from "../../utils/shadcn";
import { useEffect } from "react";
import { ReeponseAPI } from "../../apis/response";
import FormResponse from "../../types/response";
import { useParams } from "react-router-dom";

const formResponses = [
  [
    {
      question: "when would you like to place the order?",
      answer: "2024-07-20",
    },
    {
      question: "what flavor would you like?",
      answer: "chocolate",
    },
    {
      question: "what size would you like?",
      answer: "small",
    },
    {
      question: "what type of cake would you like?",
      answer: "birthday",
    },
    {
      question: "what is your name?",
      answer: "Jane Smith",
    },
    {
      question: "what is your email?",
      answer: "something@email.com",
    },
  ],
  [
    {
      question: "when would you like to place the order?",
      answer: "2024-07-20",
    },
    {
      question: "what flavor would you like?",
      answer: "chocolate",
    },
    {
      question: "what size would you like?",
      answer: "small",
    },
    {
      question: "what type of cake would you like?",
      answer: "birthday",
    },
    {
      question: "what is your name?",
      answer: "Jane Smith",
    },
    {
      question: "what is your email?",
      answer: "somethingelse@email.com",
    },
  ],
];

type Props = {};

const ResponsesPage = (props: Props) => {
  const { formID } = useParams();

  useEffect(() => {
    console.log("Fetching form responses");
    ReeponseAPI.listResponses(formID!)
      .then((response: FormResponse[]) => {
        console.log("Form Responses: ", response);
      })
      .catch((error) => {
        console.error("Error fetching form responses: ", error);
      });
  }, [formID]);

  const formHeaders = () => {
    return formResponses[0].map((response) => response.question);
  };

  return (
    <>
      <div className="flex flex-col w-full max-h-screen overflow-scroll">
        <div className="flex w-full justify-between items-center">
          <div className="flex w-full items-start p-4">
            <span className="hidden sm:block">
              <Button variant="outline">
                <EyeIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Export to CSV
              </Button>
            </span>

            <span className="ml-3 sm:block">
              <Button variant="outline">
                <RefreshCwIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Refresh
              </Button>
            </span>
          </div>

          <div className="flex flex-1 w-full p-4">
            <span className="hidden sm:block">
              <Input
                className={cn("w-full", "sm:w-96")}
                placeholder="Search responses"
              />
            </span>
          </div>
        </div>

        <div className="flex w-full max-h-screen overflow-scroll">
          <div className="flex w-full p-4">
            <Table>
              <TableCaption>Responses from your form.</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead>#</TableHead>
                  {formHeaders().map((header) => (
                    <TableHead key={header}>{header}</TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {formResponses.map((response, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      {response.map((answer, index) => (
                        <TableCell key={index}>{answer.answer}</TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponsesPage;
