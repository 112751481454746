import { SignIn } from "@clerk/clerk-react";
import { Link } from "react-router-dom";

export default function SignInPage() {
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <Link to="/" className="flex items-center justify-begin p-6">
        <img
          className="h-8 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt=""
        />
        <div className="ml-4 font-semibold text-xl">kwikforms</div>
      </Link>
      <SignIn path="/sign-in" forceRedirectUrl="/app" />
    </div>
  );
}
