import { Checkbox } from "../../ui/checkbox";
import CheckboxType from "../../../types/elements/Checkbox";
import ElementActions from "../Common/ElementActions";
import { useAppDispatch } from "../../../store/store";
import { updateResponse } from "../../../slices/formResponseSlice";
import { useEffect, useState } from "react";

type props = {
  element: CheckboxType;
  selectElement: () => void;
  deleteElement: () => void;
  copyElement: () => void;
};

const CheckboxElement = (props: props) => {
  const [elementState, setElementState] = useState<CheckboxType>(
    new CheckboxType()
  );
  const dispatch = useAppDispatch();

  const onChangeChoice = (value: string) => {
    // Create a new array of choices
    const newChoices = elementState.choices!.map((choice) => {
      if (choice.value === value) {
        return { ...choice, checked: !choice.checked };
      }
      return choice;
    });

    // Update the element state
    setElementState({ ...elementState, choices: newChoices });

    // Create a list of selected choice values
    const selectedChoices = newChoices
      .filter((choice) => choice.checked)
      .map((choice) => choice.value);
    dispatch(updateResponse({ id: props.element.id, value: selectedChoices }));
  };

  // Update the element state when the props change
  useEffect(() => {
    setElementState(props.element);
  }, [props.element]);

  return (
    <div
      className="group w-full p-4 bg-white border border-gray-100 rounded-sm hover:bg-gray-100"
      onClick={props.selectElement}
    >
      <ElementActions
        onDelete={props.deleteElement}
        onCopy={props.copyElement}
      />

      <label className="block mb-2 font-medium text-gray-900">
        {elementState.title}
      </label>
      <div className="flex flex-col space-y-2">
        {elementState.choices!.map((option, index) => (
          <div className="flex items-center space-x-2" key={index}>
            <Checkbox
              value={option.value}
              onCheckedChange={() => {
                onChangeChoice(option.value);
              }}
            ></Checkbox>
            <span>{option.value}</span>
          </div>
        ))}
      </div>
      <div className="text-sm pt-2 text-gray-500">
        {elementState.description}
      </div>
    </div>
  );
};

export default CheckboxElement;
