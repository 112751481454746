import { createSlice } from "@reduxjs/toolkit";
import FormResponse from "../types/response";

const initialState: FormResponse[] = [];

const formResponseSlice = createSlice({
  name: 'formResponse',
  initialState: initialState,
  reducers: {
    replaceResponses: (_, action) => {
      return action.payload;
    },
    updateResponse: (state, action) => {
      const { id, value } = action.payload;
      const index = state.findIndex((response) => response.questionID === id);
      state[index].answer = value;
    }
  }
});

export const { replaceResponses, updateResponse } = formResponseSlice.actions;
export const selectResponses = (state: any) => state.formResponse;

export default formResponseSlice.reducer;