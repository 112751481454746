import {
  CheckIcon,
  EyeIcon,
  InboxIcon,
  LinkIcon,
  PencilIcon,
  SettingsIcon,
  ShareIcon,
} from "lucide-react";
import { Button } from "../ui/button";
import { Link, useLocation } from "react-router-dom";

type Props = {
  publishForm: () => void;
};

const BuilderHeader = (props: Props) => {
  const location = useLocation();

  return (
    <div className="flex items-center justify-between p-2">
      <div className="min-w-0 flex-1">
        <ul className="flex flex-wrap -mb-px">
          <li className="me-2">
            <Link
              className={`inline-flex items-center px-4 py-2 border-b-2 rounded-t-lg text-gray-600 ${location.pathname.split("/").pop() === "builder" ? "border-blue-600" : ""}`}
              to="builder"
            >
              <PencilIcon
                className="-ml-0.5 mr-1.5 h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
              Build
            </Link>
          </li>
          <li className="me-2">
            <Link
              className={`inline-flex items-center px-4 py-2 border-b-2 rounded-t-lg text-gray-600 ${location.pathname.split("/").pop() === "share" ? "border-blue-600" : ""}`}
              aria-current="page"
              to="share"
            >
              <ShareIcon
                className="-ml-0.5 mr-1.5 h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
              Share
            </Link>
          </li>
          <li className="me-2">
            <Link
              className={`inline-flex items-center px-4 py-2 border-b-2 rounded-t-lg text-gray-600 ${location.pathname.split("/").pop() === "responses" ? "border-blue-600" : ""}`}
              to="responses"
            >
              <InboxIcon
                className="-ml-0.5 mr-1.5 h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
              View Response
            </Link>
          </li>
        </ul>
      </div>

      <div className="mt-5 flex lg:ml-4 lg:mt-0">
        <span className="hidden sm:block">
          <Button variant="outline">
            <EyeIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Preview
          </Button>
        </span>

        <span className="ml-3 sm:block">
          <Button variant="outline">
            <LinkIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Open
          </Button>
        </span>

        <span className="ml-3 sm:block">
          <Button variant="outline">
            <SettingsIcon
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Settings
          </Button>
        </span>

        <span className="sm:ml-3">
          <Button onClick={props.publishForm}>
            <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Publish
          </Button>
        </span>
      </div>
    </div>
  );
};

export default BuilderHeader;
