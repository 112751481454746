import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import React from "react";

type Props = {};

const FAQ = (props: Props) => {
  return (
    <div className="container p-8 mx-auto flex flex-wrap ">
      <div className="w-full max-w-2xl p-2 mx-auto rounded-2xl">
        {faqdata.map((item, index) => (
          <div key={item.question} className="mb-5">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full px-4 py-4 text-lg text-left text-gray-800 rounded-lg bg-gray-50 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-indigo-100 focus-visible:ring-opacity-75 dark:bg-trueGray-800 dark:text-gray-200">
                    <span>{item.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-indigo-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-500">
                    {item.answer}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </div>
  );
};

const faqdata = [
  {
    question: "How do I get started?",
    answer:
      "Sign up with your Google account and start by creating your first form. Post the form link on your social media accounts and start collecting responses.",
  },
  {
    question: "Is my data secure?",
    answer:
      "Yes. We use industry-standard encryption to protect your data. We never share your data with third parties.",
  },
  {
    question: "Is there a money-back guarantee?",
    answer:
      "If you're unhappy with your purchase for any reason, email us within 30 days and we'll refund you in full, no questions asked.",
  },
  {
    question: "Do you offer technical support? ",
    answer:
      "Yes. We offer technical support to all our customers. Email us at help@kiwkforms.io and we'll get back to you within 24 hours.",
  },
];

export default FAQ;
