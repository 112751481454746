import axios from 'axios';

export const api = axios.create({
  baseURL: 'http://localhost:3001',
  headers: {
    'Content-Type': 'application/json',
  },
});

// custom error handling for all APIs
const errorHandler = (error: any) => {
  const status = error.response?.status;

  if (status !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

api.interceptors.response.use((response) => {
  return response;
}, errorHandler);
