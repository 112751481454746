import { Input } from "../../../components/ui/input";
import VideoEmbed from "../../../types/elements/Video";
import { Textarea } from "../../../components/ui/textarea";

type Props = {
  element: VideoEmbed;
  onChange: (element: any) => void;
};

const VideoConfig = (props: Props) => {
  return (
    <div className="w-full py-4">
      <label className="block mb-2 font-semibold text-gray-600">
        Video Title
      </label>
      <Input
        placeholder="Placeholder Text"
        value={props.element.title}
        onChange={(e) =>
          props.onChange({ ...props.element, title: e.target.value })
        }
      />

      <label className="block mt-4 mb-2 font-semibold text-gray-600">
        Video Caption
      </label>
      <Textarea
        placeholder="Description of the video"
        rows={3}
        value={props.element.description}
        onChange={(e) =>
          props.onChange({ ...props.element, description: e.target.value })
        }
      />

      <label className="block mt-4 mb-2 font-semibold text-gray-600">
        Video URL
      </label>
      <Input
        placeholder="https://www.youtube.com/watch?v=..."
        value={props.element.url}
        onChange={(e) =>
          props.onChange({ ...props.element, url: e.target.value })
        }
      />
    </div>
  );
};

export default VideoConfig;
